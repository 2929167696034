import React from 'react';

import { useI18n } from '@mobble/i18n';
import { findMob, Mob, toMobDisplayName } from '@mobble/models/src/model/Mob';
import {
  findPaddock,
  Paddock,
  sortOptionsNameDistanceFromPaddock,
} from '@mobble/models/src/model/Paddock';
import { base64encode } from '@mobble/shared/src/core/String';
import {
  PaddockMoveMobFormQuickValues,
  useMoveMobs,
} from '@mobble/shared/src/hooks/useMoveMobs';
import { useMobs } from '@mobble/store/src/hooks/mobs';
import { usePaddocks } from '@mobble/store/src/hooks/paddocks';
import { useProperties } from '@mobble/store/src/hooks/properties';

import { Button, MobCard } from '@src/components';
import { useDialog } from '@src/hooks/useDialog';
import { useLinking } from '@src/hooks/useLinking';
import { usePaddockCardFactory } from '@src/hooks/usePaddockCardFactory';
import * as ROUTE_NAME from '@src/screens/config/routeNames';
import {
  FormBuilder,
  type FormBuilderProps,
} from '@src/stories/Views/Form/FormBuilder';

import styles from './paddockMoveMobFormQuick.scss';

export interface PaddockMoveMobFormQuickProps {
  fromPropertyId: string;
  fromPaddockId: string;
  toPaddockId: string;
  onReady: () => void;
}

export const PaddockMoveMobFormQuick: React.FC<
  PaddockMoveMobFormQuickProps
> = ({ fromPropertyId, fromPaddockId, toPaddockId, onReady }) => {
  const { formatMessage } = useI18n();
  const linkTo = useLinking();
  const { alert, close } = useDialog();
  const propertyId = fromPropertyId;
  const properties = useProperties();
  const mobs = useMobs(propertyId);
  const paddocks = usePaddocks(propertyId);

  const handleSubmitSuccess = (
    mobsCanMergeOnTargetPaddock: boolean,
    paddockTo: Paddock
  ) => {
    if (mobsCanMergeOnTargetPaddock) {
      alert(
        formatMessage({
          defaultMessage: 'Mobs can be merged',
          description: 'mobs_can_be_merged.alert.title',
        }),
        formatMessage(
          {
            defaultMessage:
              'One or more of the mobs you have moved can be merged in "{PADDOCK}"',
            description: 'mobs_can_be_merged.alert.message',
          },
          {
            PADDOCK: paddockTo.name,
          }
        ),
        [
          {
            label: formatMessage({
              defaultMessage: 'Close',
              description: 'mobs_can_be_merged.alert.cancel',
            }),
            outline: true,
            intent: 'secondary',
            onClick: () => {
              close();
              onReady();
            },
          },
          {
            label: formatMessage({
              defaultMessage: 'Merge',
              description: 'mobs_can_be_merged.alert.confirm',
            }),
            onClick: () => {
              close();
              linkTo(ROUTE_NAME.PADDOCK_DETAIL_MOBS, {
                paddockId: paddockTo.id,
              });
            },
          },
        ]
      );
    } else {
      onReady();
    }
  };

  const {
    formLoading,
    formError,
    paddockOrigin,
    paddockOptions,
    sortPaddockOptions,
    handleSubmit,
  } = useMoveMobs({
    paddockId: fromPaddockId,
    onSubmitSuccess: handleSubmitSuccess,
  });

  const makePaddockCard = usePaddockCardFactory({
    propertyId: propertyId,
    location: paddockOrigin,
  });

  const paddockOptionsWithCards = paddockOptions.map((p) => ({
    ...p,
    component: makePaddockCard(p.entity),
  }));

  const paddockFrom = findPaddock(paddocks.entities)(fromPaddockId) as Paddock;
  const initialPaddockTo = findPaddock(paddocks.entities)(
    toPaddockId
  ) as Paddock;

  const mobsOnPaddock = paddockFrom.mobs
    .map(findMob(mobs.entities))
    .filter(Boolean) as Mob[];

  const mobOptions = mobsOnPaddock.map((mob) => ({
    label: toMobDisplayName(mob, false),
    value: mob.id,
    entity: mob,
    component: (
      <MobCard
        propertyTypes={properties.selected?.types ?? []}
        mob={mob}
        style={{ flex: '1 1 auto' }}
      />
    ),
  }));

  const form: FormBuilderProps<PaddockMoveMobFormQuickValues> = {
    i18nRootKey: 'paddocks.paddock.move_mob.form',
    className: styles.form,
    flex: true,
    fields: [
      {
        name: 'paddockNameFrom',
        type: 'display',
        initialValue: paddockFrom.name,
      },
      {
        name: 'mobs',
        type: 'select-multiple',
        options: mobOptions,
        initialValue: [...paddockFrom.mobs],
        required: true,
      },

      {
        name: 'paddock',
        type: 'select',
        options: paddockOptionsWithCards,
        sortOptions: sortOptionsNameDistanceFromPaddock,
        sortFunction: sortPaddockOptions,
        required: true,
        initialValue: initialPaddockTo.id,
      },
    ],
    error: formError,
    loading: formLoading,
    onSubmit: handleSubmit,
  };

  return (
    <div className={styles.paddockMoveMobFormQuick}>
      <FormBuilder {...form} />

      <div className={styles.extraFooter}>
        <Button
          flex
          intent="secondary"
          outline
          label={formatMessage({
            defaultMessage: 'Advanced mob movement/split options',
            description: 'paddocks.paddock.move_mob.form.advanced.label',
          })}
          onClick={() => {
            linkTo(ROUTE_NAME.MODAL_MOB_MOVE, {
              paddockId: paddockFrom.id,
              mobIds: base64encode(JSON.stringify(paddockFrom.mobs)),
              toPaddockId: initialPaddockTo.id,
            });
          }}
        />
      </div>
    </div>
  );
};
