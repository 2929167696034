import { useEffect, useState } from 'react';
import { defineMessages } from 'react-intl';
import { useMessages } from '@mobble/i18n';
import {
  Quantity,
  Type,
  Units,
  unitsForType,
  unitToQuantityType,
} from '../core/Quantity';

const messages = defineMessages({
  'quantity.unit.acre.long': {
    defaultMessage: 'Acres',
    description: 'generic.quantity.unit.acre.long',
  },
  'quantity.unit.acre.short': {
    defaultMessage: 'Ac',
    description: 'generic.quantity.unit.acre.short',
  },
  'quantity.unit.acre.value': {
    defaultMessage: '{VALUE} Acres',
    description: 'generic.quantity.unit.acre.value',
  },
  'quantity.unit.cm.long': {
    defaultMessage: 'Centimeters',
    description: 'generic.quantity.unit.cm.long',
  },
  'quantity.unit.cm.short': {
    defaultMessage: 'cm',
    description: 'generic.quantity.unit.cm.short',
  },
  'quantity.unit.cm.value': {
    defaultMessage: '{VALUE} cm',
    description: 'generic.quantity.unit.cm.value',
  },
  'quantity.unit.cm2.long': {
    defaultMessage: 'Square Centimeters',
    description: 'generic.quantity.unit.cm2.long',
  },
  'quantity.unit.cm2.short': {
    defaultMessage: 'cm2',
    description: 'generic.quantity.unit.cm2.short',
  },
  'quantity.unit.cm2.value': {
    defaultMessage: '{VALUE} cm2',
    description: 'generic.quantity.unit.cm2.value',
  },
  'quantity.unit.g.long': {
    defaultMessage: 'Grams',
    description: 'generic.quantity.unit.g.long',
  },
  'quantity.unit.g.short': {
    defaultMessage: 'g',
    description: 'generic.quantity.unit.g.short',
  },
  'quantity.unit.g.value': {
    defaultMessage: '{VALUE} g',
    description: 'generic.quantity.unit.g.value',
  },
  'quantity.unit.ha.long': {
    defaultMessage: 'Hectares',
    description: 'generic.quantity.unit.ha.long',
  },
  'quantity.unit.ha.short': {
    defaultMessage: 'Ha',
    description: 'generic.quantity.unit.ha.short',
  },
  'quantity.unit.ha.value': {
    defaultMessage: '{VALUE} Ha',
    description: 'generic.quantity.unit.ha.value',
  },
  'quantity.unit.in.long': {
    defaultMessage: 'Inches',
    description: 'generic.quantity.unit.in.long',
  },
  'quantity.unit.in.short': {
    defaultMessage: 'in',
    description: 'generic.quantity.unit.in.short',
  },
  'quantity.unit.in.value': {
    defaultMessage: '{VALUE} in',
    description: 'generic.quantity.unit.in.value',
  },
  'quantity.unit.kg.long': {
    defaultMessage: 'Kilograms',
    description: 'generic.quantity.unit.kg.long',
  },
  'quantity.unit.kg.short': {
    defaultMessage: 'kg',
    description: 'generic.quantity.unit.kg.short',
  },
  'quantity.unit.kg.value': {
    defaultMessage: '{VALUE} kg',
    description: 'generic.quantity.unit.kg.value',
  },
  'quantity.unit.km.long': {
    defaultMessage: 'Kilometers',
    description: 'generic.quantity.unit.km.long',
  },
  'quantity.unit.km.short': {
    defaultMessage: 'km',
    description: 'generic.quantity.unit.km.short',
  },
  'quantity.unit.km.value': {
    defaultMessage: '{VALUE} km',
    description: 'generic.quantity.unit.km.value',
  },
  'quantity.unit.km2.long': {
    defaultMessage: 'Square Kilometers',
    description: 'generic.quantity.unit.km2.long',
  },
  'quantity.unit.km2.short': {
    defaultMessage: 'km2',
    description: 'generic.quantity.unit.km2.short',
  },
  'quantity.unit.km2.value': {
    defaultMessage: '{VALUE} km2',
    description: 'generic.quantity.unit.km2.value',
  },
  'quantity.unit.L.long': {
    defaultMessage: 'Litres',
    description: 'generic.quantity.unit.L.long',
  },
  'quantity.unit.L.short': {
    defaultMessage: 'L',
    description: 'generic.quantity.unit.L.short',
  },
  'quantity.unit.L.value': {
    defaultMessage: '{VALUE} L',
    description: 'generic.quantity.unit.L.value',
  },
  'quantity.unit.m.long': {
    defaultMessage: 'Meters',
    description: 'generic.quantity.unit.m.long',
  },
  'quantity.unit.m.short': {
    defaultMessage: 'm',
    description: 'generic.quantity.unit.m.short',
  },
  'quantity.unit.m.value': {
    defaultMessage: '{VALUE} m',
    description: 'generic.quantity.unit.m.value',
  },
  'quantity.unit.m2.long': {
    defaultMessage: 'Square Meters',
    description: 'generic.quantity.unit.m2.long',
  },
  'quantity.unit.m2.short': {
    defaultMessage: 'm2',
    description: 'generic.quantity.unit.m2.short',
  },
  'quantity.unit.m2.value': {
    defaultMessage: '{VALUE} m2',
    description: 'generic.quantity.unit.m2.value',
  },
  'quantity.unit.mg.long': {
    defaultMessage: 'Milligrams',
    description: 'generic.quantity.unit.mg.long',
  },
  'quantity.unit.mg.short': {
    defaultMessage: 'mg',
    description: 'generic.quantity.unit.mg.short',
  },
  'quantity.unit.mg.value': {
    defaultMessage: '{VALUE} mg',
    description: 'generic.quantity.unit.mg.value',
  },
  'quantity.unit.mi.long': {
    defaultMessage: 'Miles',
    description: 'generic.quantity.unit.mi.long',
  },
  'quantity.unit.mi.short': {
    defaultMessage: 'mi',
    description: 'generic.quantity.unit.mi.short',
  },
  'quantity.unit.mi.value': {
    defaultMessage: '{VALUE} mi',
    description: 'generic.quantity.unit.mi.value',
  },
  'quantity.unit.ml.long': {
    defaultMessage: 'Millilitres',
    description: 'generic.quantity.unit.ml.long',
  },
  'quantity.unit.ml.short': {
    defaultMessage: 'ml',
    description: 'generic.quantity.unit.ml.short',
  },
  'quantity.unit.ml.value': {
    defaultMessage: '{VALUE} ml',
    description: 'generic.quantity.unit.ml.value',
  },
  'quantity.unit.mm.long': {
    defaultMessage: 'Millimeters',
    description: 'generic.quantity.unit.mm.long',
  },
  'quantity.unit.mm.short': {
    defaultMessage: 'mm',
    description: 'generic.quantity.unit.mm.short',
  },
  'quantity.unit.mm.value': {
    defaultMessage: '{VALUE} mm',
    description: 'generic.quantity.unit.mm.value',
  },
  'quantity.unit.square_miles.long': {
    defaultMessage: 'Square Miles',
    description: 'generic.quantity.unit.square_miles.long',
  },
  'quantity.unit.square_miles.short': {
    defaultMessage: 'Sq mi',
    description: 'generic.quantity.unit.square_miles.short',
  },
  'quantity.unit.square_miles.value': {
    defaultMessage: '{VALUE} Sq mi',
    description: 'generic.quantity.unit.square_miles.value',
  },
  'quantity.unit.square_yards.long': {
    defaultMessage: 'Square Yards',
    description: 'generic.quantity.unit.square_yards.long',
  },
  'quantity.unit.square_yards.short': {
    defaultMessage: 'Sq yd',
    description: 'generic.quantity.unit.square_yards.short',
  },
  'quantity.unit.square_yards.value': {
    defaultMessage: '{VALUE} Sq yd',
    description: 'generic.quantity.unit.square_yards.value',
  },
  'quantity.unit.t.long': {
    defaultMessage: 'Tonnes',
    description: 'generic.quantity.unit.t.long',
  },
  'quantity.unit.t.short': {
    defaultMessage: 't',
    description: 'generic.quantity.unit.t.short',
  },
  'quantity.unit.t.value': {
    defaultMessage: '{VALUE} t',
    description: 'generic.quantity.unit.t.value',
  },
  'quantity.unit.Units.long': {
    defaultMessage: 'Units',
    description: 'generic.quantity.unit.Units.long',
  },
  'quantity.unit.Units.short': {
    defaultMessage: 'units',
    description: 'generic.quantity.unit.Units.short',
  },
  'quantity.unit.Units.value': {
    defaultMessage: '{VALUE} units',
    description: 'generic.quantity.unit.Units.value',
  },
  'quantity.unit.yd.long': {
    defaultMessage: 'Yards',
    description: 'generic.quantity.unit.yd.long',
  },
  'quantity.unit.yd.short': {
    defaultMessage: 'yd',
    description: 'generic.quantity.unit.yd.short',
  },
  'quantity.unit.yd.value': {
    defaultMessage: '{VALUE} yd',
    description: 'generic.quantity.unit.yd.value',
  },
});

type ListSelectOption = {
  label: string;
  value: string;
  selected?: boolean;
  disabled?: boolean;
};

export const useQuantityUnits = ({
  quantityType,
  value,
}: {
  quantityType: Type;
  value?: Quantity<Type, Units>;
}) => {
  const strings = useMessages(messages, {
    VALUE: value?.value ?? 0,
  });
  const [initialType, setInitialType] = useState(null);
  const units = unitsForType(quantityType);

  const options: ListSelectOption[] = [];
  const shortOptions: ListSelectOption[] = [];
  const valueOptions: ListSelectOption[] = [];

  units.forEach((unit: string) => {
    options.push({
      label: strings[`quantity.unit.${unit}.long`],
      value: unit,
      selected: unit === value?.unit,
      disabled:
        initialType && initialType !== unitToQuantityType(unit as Units),
    });
    shortOptions.push({
      label: strings[`quantity.unit.${unit}.short`],
      value: unit,
      selected: unit === value?.unit,
      disabled:
        initialType && initialType !== unitToQuantityType(unit as Units),
    });
    valueOptions.push({
      label: strings[`quantity.unit.${unit}.value`],
      value: unit,
      selected: unit === value?.unit,
      disabled:
        initialType && initialType !== unitToQuantityType(unit as Units),
    });
  });

  // If value is set on mount, disable changing between unit types (MassVolume)
  useEffect(() => {
    if (value?.unit) {
      const valueQuantityType = unitToQuantityType(value.unit);
      setInitialType(valueQuantityType);
    }
  }, []);

  return {
    strings,
    units,
    options,
    shortOptions,
    valueOptions,
  };
};
