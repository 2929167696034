import { createTransform } from 'reduxjs-toolkit-persist';
import {
  type Storage,
  type PersistConfig,
} from 'reduxjs-toolkit-persist/lib/types';
import autoMergeLevel1 from 'reduxjs-toolkit-persist/lib/stateReconciler/autoMergeLevel1';

const StatusTransform = createTransform(
  (state: Record<any, any>, _: string) => {
    if (state?.extStatus) {
      return {
        ...state,
        extStatus: {},
      };
    }
    return state;
  },
  (state: Record<any, any>, _: string) => {
    if (state?.extStatus) {
      return {
        ...state,
        extStatus: {},
      };
    }
    return state;
  }
);

export const getPersistConfig = <S = any>(
  storage: Storage
): PersistConfig<S> => ({
  storage,
  key: 'root',
  // manualSync: false,
  version: 36,
  stateReconciler: autoMergeLevel1,
  transforms: [StatusTransform],
  blacklist: [
    //
    'historicEvents',
    'network',
    'paddockGroupedStockingRate',
    'paddockStockingRates',
  ],
});
