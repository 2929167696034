import React, { useCallback } from 'react';
import classNames from 'classnames/bind';

import { useI18n } from '@mobble/i18n';
import {
  BaseEntity,
  EntitiesFilterItem,
  EntitiesFilterItemData,
} from '@mobble/models/src/model/BaseEntity';
import {
  filterCompare,
  FilterDateRange,
  type FilterItem,
  makeDateRangeFromFilterDateRange,
  makeFilterDateRange,
  makeFilterEquals,
} from '@mobble/models/src/model/Filter';

import Button from '@src/components/Button';
import DateRange from '@src/components/DateRange';
import { type ListProps } from '@src/components/List';
import { useTimeZone } from '@src/hooks/useTimeZone';
import { Text } from '@src/stories/Components/UI/Text';
import { InlineOption } from '@src/stories/Components/UX/InlineOption';
import {
  ModalFlyUp,
  ModalFlyUpProps,
} from '@src/stories/Components/UX/ModalFlyUp';

import FixedHeader from '../Misc/FixedHeader';

import styles from './entitiesFilter.scss';
const cx = classNames.bind(styles);

export interface EntitiesFilterProps<Entity extends BaseEntity>
  extends ModalFlyUpProps {
  entities: Entity[];
  filter: FilterItem[];

  hideFixedFilters?: boolean;

  setFilter: (filter: FilterItem) => void;
  toggleFilter: (filter: FilterItem) => void;
  clearFilter: () => void;

  items: () => EntitiesFilterItem<Entity>[];
  applyFilter?: (entities: Entity[], filter: FilterItem[]) => Entity[];
  onClose: () => void;
}

function EntitiesFilterComponent<Entity extends BaseEntity>({
  isOpen,
  items,
  entities,
  filter,
  hideFixedFilters,
  setFilter,
  toggleFilter,
  clearFilter,
  applyFilter,
  onClose,
  ...others
}: EntitiesFilterProps<Entity>) {
  const { formatMessage } = useI18n();
  const { region } = useTimeZone();

  const makeData = useCallback(() => {
    const filteredEntities = applyFilter
      ? applyFilter(entities, filter)
      : entities;

    const data = items().map((item) => ({
      ...item,
      data:
        typeof item.data === 'function'
          ? item.data(filteredEntities)
          : typeof item.data === 'undefined'
          ? [null]
          : item.data,
    }));

    return data;
  }, [applyFilter, items, entities, filter]);

  const data = makeData();

  const fixedItems = data.filter((item) => item.meta?.fixed);
  const showFixedHeader = !hideFixedFilters && fixedItems.length > 0;

  const renderSectionHeader: ListProps['renderSectionHeader'] = (section) => {
    const sectionClasses = cx({
      [styles.sectionHeader]: true,
      // @ts-expect-error -- need to correctly type section
      [styles.isFixed]: section.meta?.fixed,
    });

    // Not supported on web
    // @ts-expect-error -- need to correctly type section
    if (section.group === 'number') {
      return null;
    }

    return (
      <header className={sectionClasses}>
        <Text variant="larger" bold i18n={section?.title} />
      </header>
    );
  };

  const renderFilter = (
    group: string,
    type: string,
    itemData?: EntitiesFilterItemData
  ) => {
    switch (type) {
      case 'select':
      case 'select-multiple': {
        const equalsFilter = makeFilterEquals(String(itemData.value));

        return (
          <InlineOption
            type={type === 'select' ? undefined : 'checkbox'}
            label={itemData.label ?? itemData.value}
            value={itemData.value}
            selected={Boolean(
              filter.find(
                (a) =>
                  a.group === group && filterCompare(a.filter)(equalsFilter)
              )
            )}
            onClick={() => {
              toggleFilter({
                group,
                filter: equalsFilter,
              });
            }}
          />
        );
      }

      case 'date-range': {
        const filterItem = filter.find((a) => a.group === group)?.filter;
        const inputValue = makeDateRangeFromFilterDateRange(
          filterItem as FilterDateRange
        );

        return (
          <DateRange
            id="entities-filter-date-range"
            aria-label={formatMessage({
              defaultMessage: 'Filter date range',
              description: 'entities.filter.date_range.label',
            })}
            value={inputValue}
            countryCode={region}
            showSelect
            onChange={(range) => {
              setFilter({
                group,
                filter: makeFilterDateRange([range.start, range.end]),
              });
            }}
            className={styles.dateRangeInput}
          />
        );
      }

      default: {
        return null;
      }
    }
  };

  const footer = (
    <>
      <Button
        disabled={filter.length === 0}
        outline
        intent="secondary"
        label={formatMessage({
          defaultMessage: 'Clear filter',
          description: 'entities.entities_filter.footer.button.clear.label',
        })}
        onClick={() => {
          clearFilter();
          onClose();
        }}
      />
      <Button
        outline
        label={formatMessage({
          defaultMessage: 'Close',
          description: 'entities.entities_filter.footer.button.close.label',
        })}
        onClick={() => {
          onClose();
        }}
      />
    </>
  );

  return (
    <>
      {showFixedHeader && (
        <FixedHeader>
          {fixedItems.map((item) => {
            return renderFilter(item.group, item.type);
          })}
        </FixedHeader>
      )}

      <ModalFlyUp
        isOpen={isOpen}
        title={formatMessage({
          defaultMessage: 'Filter options',
          description: 'entities.entities_filter.title',
        })}
        listProps={{
          items: data,
          keyExtractor: (item, index) => item?.group ?? index,
          renderSectionHeader,
          renderItem: (
            data: EntitiesFilterItemData,
            _index: number,
            section: any
          ) => {
            if (!section) {
              return <strong>Missing section</strong>;
            }
            return renderFilter(section.group, section.type, data);
          },
        }}
        footer={footer}
        onClose={onClose}
        className={styles.EntitiesFilterModal}
        {...others}
      />
    </>
  );
}

export const EntitiesFilter = React.memo(EntitiesFilterComponent, (a, b) => {
  return a.isOpen === b.isOpen && a.filter === b.filter;
});
