import { createAsyncThunk } from '@reduxjs/toolkit';
import mobbleService from '@mobble/service';
import { type SplitAndMoveMobsInput } from '@mobble/service/src/ext/mob-movements';
import {
  availableSortOptions,
  type Paddock,
} from '@mobble/models/src/model/Paddock';
import { type Mob } from '@mobble/models/src/model/Mob';
import { type ExtStatusPerActionPerParent } from '../lib/ExtStatus';
import { entitySliceFactory } from '../lib/entitySliceFactory';
import { type FilterPerProperty } from '../lib/filter';
import { SortPerProperty } from '../lib/sort';
import { thunkGetAll as thunkGetAllPaddockGeometries } from './paddockGeometry';
import { thunkGetAll as thunkGetAllHistoricEvents } from './historicEvents';

export interface PaddocksState {
  extStatus: ExtStatusPerActionPerParent;
  entities: Paddock[];
  filter: FilterPerProperty;
  sort: SortPerProperty;
}

export const defaultState: PaddocksState = {
  entities: [],
  extStatus: {},
  filter: {},
  sort: {},
};

const entitySlice = entitySliceFactory<PaddocksState, Paddock>({
  name: 'paddocks',
  initialState: defaultState,
  defaultSort: availableSortOptions[0]?.settings,
  extraReducers: (builder) => {
    builder.addCase(thunkMergeMobs.fulfilled, (state, action) => {
      state.entities = state.entities.map((paddock) => {
        if (paddock.id === action.meta.arg.paddockId) {
          return {
            ...paddock,
            mobs: [
              ...paddock.mobs.filter(
                (a) => !action.meta.arg.mobIds.includes(a)
              ),
              action.payload,
            ],
          };
        }
        return paddock;
      });
    });
  },
  toParentId: ({ propertyId }: Paddock) => propertyId,
  onGetAll: (args, dispatch) =>
    mobbleService.api.paddocks
      .get(args)
      .then(({ entities, historicEvents }) => {
        historicEvents.forEach((payload) => {
          dispatch(
            thunkGetAllHistoricEvents.fulfilled(payload as any, '', {
              parentId: args.parentId,
              extFilter: { paddockId: payload.paddockId },
            })
          );
        });

        return { entities };
      }),
  onFind: mobbleService.api.paddocks.find,
  onCreate: mobbleService.api.paddocks.create,
  onUpdate: mobbleService.api.paddocks.update,
  onDelete: mobbleService.api.paddocks.remove,
  afterMutation: async ({ entity }, dispatch) => {
    dispatch(thunkGetAll({ parentId: entity.propertyId }));
    dispatch(thunkGetAllPaddockGeometries({ parentId: entity.propertyId }));
  },
});

export const {
  thunkGetAll,
  thunkFind,
  thunkCreate,
  thunkUpdate,
  thunkDelete,
  //
  proxyUseEntities,
  proxyUseEntity,
} = entitySlice;
export const { flush, updateFilter } = entitySlice.slice.actions;
export const { reducer } = entitySlice.slice;

export type MoveMobsInput = SplitAndMoveMobsInput & {
  // required for offline actions
  paddockFrom: Paddock;
  paddockTo: Paddock;
  meta?: {
    mobMoveNames: string[];
    mobSplitNames: string[];
  };
};

export const thunkMoveMobs = createAsyncThunk<any, MoveMobsInput>(
  'paddocks/moveMobs',
  async (args, { rejectWithValue }) => {
    return mobbleService.api.mobMovements
      .splitAndMove(args)
      .then((res) => res)
      .catch((error) => {
        return rejectWithValue(error);
      });
  }
);

export interface MergeMobsInput {
  propertyId: string;
  paddockId: string;
  mobIds: string[];
  classes: (string | null)[];
  ages: number[];
  DSE: number;
  date: any;
  meta?: {
    paddockNames: string;
    mobsNames: string[];
  };
}

export const thunkMergeMobs = createAsyncThunk<Mob['id'], MergeMobsInput>(
  'paddocks/mergeMobs',
  async (args) => {
    return mobbleService.api.mobMovements
      .merge({
        propertyId: args.propertyId,
        paddockId: args.paddockId,
        mobIds: args.mobIds,
        classes: args.classes,
        ages: args.ages,
        DSE: args.DSE,
        date: args.date,
      })
      .then((res) => res);
  }
);

export default entitySlice.slice.reducer;
