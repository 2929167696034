import React, { useState } from 'react';
import * as Yup from 'yup';

import { useI18n } from '@mobble/i18n';
import {
  findMob,
  getMobDisplayName,
  getMobSize,
  type Mob,
} from '@mobble/models/src/model/Mob';
import { type Paddock } from '@mobble/models/src/model/Paddock';
import {
  getMobsOnPaddockAndSamePaddockGroup,
  type PaddockGroup,
} from '@mobble/models/src/model/PaddockGroup';
import {
  type ConfiguredPropertyType,
  type ConfiguredPropertyTypeGroupCustom,
  getLivestockParentIdForValue,
} from '@mobble/models/src/model/Property';
import {
  type SortOption,
  type SortSetting,
} from '@mobble/models/src/model/Sort';
import { daysAgo, now, subtractDays } from '@mobble/shared/src/core/Date';
import {
  BACKDATING_DAYS_LIMIT,
  type PaddockMoveMobFormValues,
} from '@mobble/shared/src/hooks/useMoveMobs';

import { type ListSelectOption } from '@src/components/ListSelect';

import { FormBuilder, type FormBuilderProps } from '../Form/FormBuilder';

import { MobsSplit } from './PaddockMoveMobForm/MobsSplit';

import styles from './PaddockMoveMobForm/styles.scss';

export interface PaddockMoveMobFormProps {
  paddockOptions: ListSelectOption[];
  paddockSortOptions: SortOption[];
  sortPaddockOptionsFunction: (
    options: ListSelectOption[],
    sortSettings: SortSetting[]
  ) => ListSelectOption[];
  propertyTypes: ConfiguredPropertyType[];
  propertyOptions: ListSelectOption[];
  paddock: Paddock;
  paddocks: Paddock[];
  mobs: Mob[];
  paddockGroups: PaddockGroup[];
  error?: string;
  loading?: boolean;
  selectedMobs?: string[];
  selectedProperty: string;
  selectedToPaddockId?: string;
  onCancel: () => void;
  onSubmit: (formValues: PaddockMoveMobFormValues) => void;
  onChangeProperty: (propertyId: string) => void;
  onCreateCustomField: (
    group: ConfiguredPropertyTypeGroupCustom,
    livestockTypeId: string,
    label: string
  ) => Promise<void>;
}

export const PaddockMoveMobForm: React.FC<PaddockMoveMobFormProps> = ({
  sortPaddockOptionsFunction,
  propertyTypes,
  paddockOptions,
  paddockSortOptions,
  propertyOptions,
  paddock,
  paddocks,
  mobs,
  paddockGroups,
  selectedMobs,
  selectedProperty,
  selectedToPaddockId,
  error,
  loading,
  onCancel,
  onSubmit,
  onCreateCustomField,
  onChangeProperty,
}) => {
  const { formatMessage } = useI18n();
  const selectableMobs = getMobsOnPaddockAndSamePaddockGroup(
    paddockGroups,
    paddocks
  )(paddock.id);
  const [backdatingAlertOpen, setBackdatingAlertOpen] = useState(false);

  const mobOptions = selectableMobs.map((mobId) => ({
    label: getMobDisplayName(mobs)(mobId) || 'unknown',
    labelExtra: getMobSize(mobs)(mobId),
    value: mobId,
  }));

  const checkUserHasMoreThanOneProperty = () => propertyOptions.length > 1;

  const handleCreateCustomField =
    (mob: Mob) =>
    (type: ConfiguredPropertyTypeGroupCustom) =>
    (value: string): Promise<void> => {
      const liveStockTypeId = getLivestockParentIdForValue(
        mob.type,
        propertyTypes
      );
      if (!liveStockTypeId) {
        return Promise.reject();
      }

      return onCreateCustomField(type, liveStockTypeId, value);
    };

  const NOW = now();
  const MIN_DATE = subtractDays(NOW, BACKDATING_DAYS_LIMIT);

  const form: FormBuilderProps<PaddockMoveMobFormValues> = {
    flex: true,
    i18nRootKey: 'paddocks.paddock.move_mob.form',
    className: styles.paddockMoveMobForm,
    fields: [
      {
        name: 'paddockNameFrom',
        type: 'display',
        initialValue: paddock.name,
      },
      {
        name: 'mobs',
        type: 'select-multiple',
        options: mobOptions,
        initialValue: selectedMobs
          ? [...selectedMobs]
          : [...mobOptions.map((a) => a.value)],
        required: true,
      },
      {
        name: 'mobsSplit',
        type: 'custom',
        initialValue: '{}',
        component: ({ values, onSetValue }) => (
          <MobsSplit
            paddocks={paddocks}
            onCreateCustomField={handleCreateCustomField}
            propertyTypes={propertyTypes}
            value={JSON.parse(values.mobsSplit || '{}')}
            selectedMobs={
              values.mobs.map(findMob(mobs)).filter(Boolean) as Mob[]
            }
            onChange={(value) => onSetValue(JSON.stringify(value))}
          />
        ),
        containerComponent: ({ input }) => (
          <div className={styles.mobSplitWrapperContainer}>{input}</div>
        ),
      },
      {
        name: 'property',
        type: 'select',
        options: propertyOptions,
        required: true,
        initialValue: selectedProperty,
        onChange: (values) => {
          onChangeProperty(String(values.property));
          return values;
        },
        show: checkUserHasMoreThanOneProperty,
      },
      {
        name: 'paddock',
        type: 'select',
        options: paddockOptions,
        sortOptions: paddockSortOptions,
        sortFunction: sortPaddockOptionsFunction,
        required: true,
        initialValue: selectedToPaddockId,
      },
      {
        name: 'date',
        type: 'date',
        initialValue: NOW,
        // min: MIN_DATE,
        // max: TODAY,
        validation: Yup.date()
          .min(
            MIN_DATE,
            formatMessage(
              {
                defaultMessage:
                  'Backdating is limited to {BACKDATING_DAYS_LIMIT} days. Please select a more recent date.',
                description: 'Mob move error: Backdating exceeds limit.',
              },
              {
                BACKDATING_DAYS_LIMIT,
              }
            )
          )
          .max(
            NOW,
            formatMessage({
              defaultMessage: 'Mob moves cannot be scheduled in the future.',
              description: 'Mob move error: Date cannot be in the future.',
            })
          )
          .required('Date is required'),
      },
    ],
    alert: {
      variant: 'info',
      open: backdatingAlertOpen,
      title: formatMessage({
        defaultMessage: 'Backdated mob move',
        description: 'backdating_notification.alert.title',
      }),
      message: formatMessage({
        defaultMessage:
          'Backdating a mob move updates the days grazed and rested counters but does not update historic livestock reports.',
        description: 'backdating_notification.alert.description',
      }),
    },
    error,
    loading,
    onSubmit,
    onCancel,
    onChange: (values) => {
      if (values.date) {
        const dateDaysFromNow = daysAgo(values.date);
        const isBackdating =
          dateDaysFromNow > 0 && dateDaysFromNow < BACKDATING_DAYS_LIMIT;
        setBackdatingAlertOpen(isBackdating || false);
      }
    },
  };

  return <FormBuilder {...form} />;
};
