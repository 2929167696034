import { type User } from '@mobble/models/src/model/User';
import { type AuthState, updateUser } from '../reducers/auth';
import { useProperties } from './properties';
import { useRootSelector, useRootDispatch } from './root';
import { useSettings } from './settings';

export const useAuth = (): AuthState => useRootSelector((s) => s.auth);

export const useAuthIsLoggedIn = () => useAuth().user !== null;

export const useGetUser = () => {
  const properties = useProperties();
  const { settings } = useSettings();
  const debugUserRole = settings.debugUserRole;

  const user = useAuth().user;

  if (!user) {
    return undefined;
  }

  return {
    ...user,
    role: debugUserRole ?? properties?.selected?.currentUserRole,
  };
};

export const useUpdateUser = () => {
  const dispatch = useRootDispatch();
  return {
    updateUser: (user: User) => dispatch(updateUser(user)),
  };
};
