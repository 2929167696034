import React from 'react';
import { MapMouseEvent, MapTouchEvent } from 'mapbox-gl';

import { MapViewport } from '@mobble/models/src/model/Map';
import {
  type BoundingBox,
  isValidBoundingBox,
} from '@mobble/models/src/model/MapGeometry';
import { useMapSettings } from '@mobble/store/src/hooks/map';

import { useMap } from '@src/stories/Mapbox/lib/React/Context';

import { useMapPluginsContext } from '../Context';

import { cameraPanToUserLocation } from './User';

export const Effect: React.FC = () => {
  const map = useMap();
  const { mapProperties, name } = useMapPluginsContext();
  const { getViewport, updateViewport } = useMapSettings();
  const storedViewport = name ? getViewport(name) : null;

  const boundingBox = (() => {
    if (isValidBoundingBox(storedViewport?.boundingBox)) {
      return storedViewport.boundingBox;
    } else if (isValidBoundingBox(mapProperties.boundingBox)) {
      return mapProperties.boundingBox;
    }

    return null;
  })();

  const onViewportChanged = (ev: MapMouseEvent | MapTouchEvent) => {
    const NE_SW = ev.target.getBounds().toArray();
    const newViewport: MapViewport = {
      version: new Date().getTime(),
      zoom: ev.target.getZoom(),
      boundingBox: [...NE_SW[0], ...NE_SW[1]] as BoundingBox,
    };

    updateViewport(name, newViewport);
  };

  React.useEffect(() => {
    const setBounds = (bounds) => {
      map.fitBounds(
        [
          [bounds[0], bounds[1]],
          [bounds[2], bounds[3]],
        ],
        { linear: true, animate: true }
      );
    };

    if (name) {
      map.on('moveend', onViewportChanged);
    }

    if (boundingBox && mapProperties.items.length > 0) {
      setBounds(boundingBox);
    } else {
      cameraPanToUserLocation(map).catch((err) => {
        console.error(err);
      });
    }

    return () => {
      if (name) {
        map.off('moveend', onViewportChanged);
      }
    };
    // // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  return null;
};

export default {
  Effect,
};
