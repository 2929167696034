import { toISO8601 } from '@mobble/shared/src/core/Date';
import { promiseSequentialWithSleep } from '@mobble/shared/src/core/Promise';
import { type ApiPrelude } from '../types';

export interface SplitAndMoveMobsInput extends MoveMobsInput {
  mobsSplit: Record<string, MobSplitInput>;
}

export interface MobSplitInput {
  remainingClasses: string[];
  remainingAges: number[];
  remainingGender: string;
  newClasses: string[];
  newAges: number[];
  newGender: string;
  newSize: number;
}

export const splitAndMove =
  (prelude: ApiPrelude) =>
  async (input: SplitAndMoveMobsInput): Promise<any[]> => {
    const tasks: (() => Promise<any>)[] = [];

    const moving = input.mobIds.filter((mobId) => !input.mobsSplit[mobId]);
    const splitting = input.mobIds
      .map((mobId) => [mobId, input.mobsSplit[mobId]])
      .filter(([_, b]) => Boolean(b)) as [string, MobSplitInput][];

    if (moving.length > 0) {
      tasks.push(() =>
        moveMobs(prelude)({
          propertyId: input.propertyId,
          toPaddockId: input.toPaddockId,
          date: input.date,
          mobIds: moving,
        })
      );
    }

    tasks.push(
      ...splitting.map(
        ([id, mobSplitInput]) =>
          () =>
            splitMob(prelude)({
              id,
              //
              toPaddockId: input.toPaddockId,
              propertyId: input.propertyId,
              date: input.date,
              //
              numberToMove: mobSplitInput.newSize,
              remainingAges: mobSplitInput.remainingAges,
              remainingClasses: mobSplitInput.remainingClasses,
              remainingGender: mobSplitInput.remainingGender,
              movingAges: mobSplitInput.newAges,
              movingClasses: mobSplitInput.newClasses,
              movingGender: mobSplitInput.newGender,
            })
      )
    );
    const res = await promiseSequentialWithSleep(tasks);
    return res;
  };

interface MoveMobsInput {
  propertyId: string;
  toPaddockId: string;
  mobIds: string[];
  date: string;
}

const moveMobs =
  (prelude: ApiPrelude) =>
  async (input: MoveMobsInput): Promise<any> => {
    const response = await prelude.fetch({
      uri: `/property/${input.propertyId}/multiple-mob-move`,
      method: 'POST',
      variables: {
        propertyId: input.propertyId,
        toPaddockId: input.toPaddockId,
        mobIds: input.mobIds,
        date: toISO8601(input.date),
      },
    });

    return response;
  };

interface SplitMobInput {
  id: string;
  toPaddockId: string;
  propertyId: string;
  numberToMove: number;
  date: any;
  remainingAges: number[];
  remainingClasses?: string[] | null;
  remainingGender: string;
  movingAges: number[];
  movingClasses?: string[] | null;
  movingGender: string;
}

const splitMob =
  (prelude: ApiPrelude) =>
  async (input: SplitMobInput): Promise<any> => {
    const response = await prelude.fetch({
      uri: `/property/${input.propertyId}/mob-split`,
      method: 'POST',
      variables: {
        id: input.id,
        toPaddockId: input.toPaddockId,
        propertyId: input.propertyId,
        numberToMove: input.numberToMove,
        date: toISO8601(input.date),
        remainingAges: input.remainingAges,
        remainingClasses: input.remainingClasses,
        remainingGender: input.remainingGender,
        movingAges: input.movingAges,
        movingClasses: input.movingClasses,
        movingGender: input.movingGender,
      },
    });

    return response;
  };

interface MergeMobsInput {
  propertyId: string;
  paddockId: string;
  mobIds: string[];
  classes: (string | null)[];
  ages: number[];
  DSE: number;
  date: any;
}

export const merge =
  (prelude: ApiPrelude) =>
  async (input: MergeMobsInput): Promise<string> => {
    const response = await prelude.fetch({
      uri: `/property/${input.propertyId}/mob-merge`,
      method: 'POST',
      variables: {
        propertyId: input.propertyId,
        paddockId: input.paddockId,
        mobIds: input.mobIds,
        classes: input.classes,
        ages: input.ages,
        DSE: Number(input.DSE),
        date: toISO8601(input.date),
      },
    });

    return response;
  };
