import { useEffect, useState } from 'react';

import vars from '@src/styles/_variables.scss';

export enum MediaQuerySize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  XLarge = 'xlarge',
}

const MEDIA_QUERIES: Record<MediaQuerySize, string> = {
  small: vars['breakpoint-small'],
  medium: vars['breakpoint-medium'],
  large: vars['breakpoint-large'],
  xlarge: vars['breakpoint-xlarge'],
};

const SMALL = window.matchMedia(MEDIA_QUERIES.small);
const MEDIUM = window.matchMedia(MEDIA_QUERIES.medium);
const LARGE = window.matchMedia(MEDIA_QUERIES.large);
const XLARGE = window.matchMedia(MEDIA_QUERIES.xlarge);

/**
 * Returns the current media query value
 */
export function useMediaQuery() {
  let defaultState = MediaQuerySize.Small;
  if (SMALL.matches) {
    defaultState = MediaQuerySize.Small;
  } else if (MEDIUM.matches) {
    defaultState = MediaQuerySize.Medium;
  } else if (LARGE.matches) {
    defaultState = MediaQuerySize.Large;
  } else if (XLARGE.matches) {
    defaultState = MediaQuerySize.XLarge;
  }
  const [size, setSize] = useState(defaultState);

  const handleSmallChange = (ev: MediaQueryListEvent) => {
    if (ev.matches) {
      setSize(MediaQuerySize.Small);
    }
  };
  const handleMediumChange = (ev: MediaQueryListEvent) => {
    if (ev.matches) {
      setSize(MediaQuerySize.Medium);
    }
  };
  const handleLargeChange = (ev: MediaQueryListEvent) => {
    if (ev.matches) {
      setSize(MediaQuerySize.Large);
    }
  };
  const handleXlargeChange = (ev: MediaQueryListEvent) => {
    if (ev.matches) {
      setSize(MediaQuerySize.XLarge);
    }
  };

  useEffect(() => {
    setSize(defaultState);
  }, [defaultState]);

  useEffect(() => {
    SMALL.addEventListener('change', handleSmallChange);
    MEDIUM.addEventListener('change', handleMediumChange);
    LARGE.addEventListener('change', handleLargeChange);
    XLARGE.addEventListener('change', handleXlargeChange);

    return () => {
      SMALL.removeEventListener('change', handleSmallChange);
      MEDIUM.removeEventListener('change', handleMediumChange);
      LARGE.removeEventListener('change', handleLargeChange);
      XLARGE.removeEventListener('change', handleXlargeChange);
    };
  });

  return size;
}
