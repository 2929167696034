import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';

import { useI18n } from '@mobble/i18n/src';
import * as Quantity from '@mobble/shared/src/core/Quantity';
import { useQuantityUnits } from '@mobble/shared/src/hooks/useQuantityUnits';

import Input, { InputProps } from '@src/components/Input';
import ListSelect from '@src/components/ListSelect';
import { MediaQuerySize, useMediaQuery } from '@src/hooks/useMediaQuery';

import styles from './InputQuantity.scss';
const cx = classNames.bind(styles);

export interface InputQuantityProps<T extends Quantity.Type>
  extends Omit<InputProps, 'type' | 'value' | 'onChange'> {
  quantityType: T;
  value?: Quantity.Quantity<T, Quantity.Units>;

  /**
   * Callback for when a valid quantity is entered
   */
  onChange: (quantity: Quantity.Quantity<T, Quantity.Units>) => void;
}

/**
 * InputQuantity component
 */
const InputQuantity: React.FC<InputQuantityProps<Quantity.Type>> = ({
  quantityType,
  value,
  onChange,
  className,
  ...others
}) => {
  const { formatMessage } = useI18n();
  const mediaSize = useMediaQuery();
  const { options } = useQuantityUnits({
    quantityType,
    value: value,
  });
  const [internalValue, setInternalValue] = useState(
    value?.value ? String(value?.value) : ''
  );
  const unitValue = value?.unit;
  const isSmall =
    mediaSize === MediaQuerySize.Small || mediaSize === MediaQuerySize.Medium;
  const rootClasses = cx(
    {
      InputQuantity: true,
    },
    className
  );

  // onChange event when valid quantity is entered
  useEffect(() => {
    const parsedInternalValue = parseFloat(internalValue);

    // check quantity is valid, default value of 0 if undefined
    const isValid = Quantity.isQuantity({
      type: quantityType,
      unit: unitValue,
      value: parsedInternalValue || 0,
    });

    if (isValid) {
      onChange(
        Quantity.makeQuantity(
          quantityType,
          unitValue,
          parsedInternalValue || undefined
        )
      );
    }
  }, [unitValue, internalValue, quantityType]);

  const handleInputChange = (value: string) => {
    setInternalValue(value);
  };

  const handleUnitChange = (values: (string | number)[]) => {
    onChange(
      Quantity.makeQuantity(
        quantityType,
        values[0] as Quantity.Units,
        parseFloat(internalValue) || undefined
      )
    );
  };

  return (
    <div className={rootClasses}>
      <Input
        type="float"
        value={internalValue}
        onChange={handleInputChange}
        className={styles.input}
        {...others}
      />
      <ListSelect
        id="single-select"
        heading={formatMessage({
          defaultMessage: 'Select unit',
          description: 'generic.quantity.unit.select',
        })}
        placeholder={formatMessage({
          defaultMessage: 'Unit',
          description: 'generic.quantity.unit.select.placeholder',
        })}
        selectionLabel={
          unitValue
            ? formatMessage({
                id: `generic.quantity.unit.${unitValue}.short`,
                defaultMessage: [],
              })
            : formatMessage({
                defaultMessage: 'Unit',
                description: 'generic.quantity.unit.select.placeholder',
              })
        }
        hideHeader={!isSmall}
        options={options}
        onChange={handleUnitChange}
        className={styles.select}
      />
    </div>
  );
};

export default InputQuantity;
