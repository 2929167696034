import { type User } from '@mobble/models';
import { toError } from '@mobble/shared/src/core/Error';
import { sleep } from '@mobble/shared/src/core/Promise';

import { MobbleService } from '../index';
import { FetcherInterface } from '../lib/Fetcher';

export interface AuthProxyUser {
  uid: string;
  getIdToken: (refresh?: boolean) => Promise<string>;
}

export interface AuthProxy {
  getCurrentUser: () => Promise<AuthProxyUser>;
  signInWithCustomToken: (token: string) => Promise<{ user?: AuthProxyUser }>;
  createUserWithEmailAndPassword: (
    email: string,
    password: string
  ) => Promise<{ user?: AuthProxyUser }>;
  onAuthStateChanged: (
    callback: (fireBaseUser: any | null) => void
  ) => () => void;
  signOut: () => Promise<void>;
  sendPasswordResetEmail: (email: string) => Promise<void>;
}

export interface SignUpValues {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  phone: string;
  country: string;
  address: string;
  farmRole: string;
  tz: string;

  trialKey: string;
}

export interface MobbleAuth {
  getToken: () => Promise<string>;
  getUserId: () => Promise<string>;
  signIn: (email: string, password: string) => Promise<AuthProxyUser>;
  signUpForTrial: (params: SignUpValues) => Promise<User>;
  signOut: () => Promise<void>;
  forgotPassword: (email: string) => Promise<void>;
  onAuthStateChanged: (callback: (user: User | null) => void) => () => void;
  createUserWithEmailAndPassword: (
    email: string,
    password: string
  ) => Promise<{ user: User; token: string }>;
}

export const defaultAuth = {
  getToken: () => Promise.reject(),
  getUserId: () => Promise.reject(),
  signIn: () => Promise.reject(),
  signUpForTrial: () => Promise.reject(),
  signOut: () => Promise.reject(),
  forgotPassword: () => Promise.reject(),
  onAuthStateChanged: () => () => {},
  createUserWithEmailAndPassword: () => Promise.reject(),
};

export const createAuth = ({
  auth,
  fetch,
  getUser,
  setUser,
  isOnline,
}: {
  auth: AuthProxy;
  fetch: FetcherInterface['fetch'];
  getUser: (authToken?: string) => Promise<User>;
  setUser: (user: User) => void;
  isOnline?: () => Promise<boolean>;
}): MobbleAuth => {
  const getToken = async (): Promise<string> => {
    const online = isOnline ? await isOnline() : true;
    if (!online) {
      return Promise.reject('Offline; Unable to retrieve auth token');
    }
    const currentUser = await auth.getCurrentUser();
    const token = await currentUser?.getIdToken();
    if (!token) {
      const tokenRefreshed = await currentUser?.getIdToken(true);
      if (tokenRefreshed) {
        return tokenRefreshed;
      }
      return Promise.reject('Unable to retrieve user token');
    }
    return token;
  };

  const getUserId = async () => {
    const currentUser = await auth.getCurrentUser();
    return currentUser?.uid ?? '';
  };

  const signIn = async (
    email: string,
    password: string
  ): Promise<AuthProxyUser> => {
    const token = await fetch({
      uri: '/auth/sign-in',
      method: 'POST',
      variables: {
        email,
        password,
      },
      token: false,
    });

    const userCredential = await auth.signInWithCustomToken(token);
    return userCredential?.user;
  };

  const onAuthStateChanged = (
    onStatusChanged: (user: null | User) => void
  ): (() => void) => {
    return auth.onAuthStateChanged(async (firebaseUser) => {
      if (firebaseUser) {
        const user = await getUser();
        setUser(user);
        if (user) {
          return onStatusChanged(user);
        }
      }
      return onStatusChanged(null);
    });
  };

  const forgotPassword = async (email: string): Promise<void> => {
    try {
      await auth.sendPasswordResetEmail(email);
    } catch (_) {}
  };

  const signOut = async (): Promise<void> => {
    try {
      await auth.signOut();
    } catch (_) {}
  };

  /**
   * Wait for user to be created before fetching it
   * This seems to prevent the error:
   *  "access denied, unable to authenticate user: access denied, error resolving user record: error inserting firebase user object into database: you@mobble.io, models: unable to insert into users: pq: duplicate key value violates unique constraint \"users_email_key\""
   */
  const getMeDelayed = async (
    token: string,
    delay = 2000,
    retries = 3
  ): Promise<User> => {
    await sleep(delay);
    try {
      const user = await MobbleService.getInstance().api.me.get(token);
      return user;
    } catch (err) {
      if (retries > 0) {
        return getMeDelayed(token, delay + 1000, retries--);
      }
      throw new Error('Unable to retrieve newly created user');
    }
  };

  const createUserWithEmailAndPassword = async (
    email: string,
    password: string
  ): Promise<{
    user: User;
    token: string;
  }> => {
    const userCredential = await auth.createUserWithEmailAndPassword(
      email.trim(),
      password
    );

    if (userCredential.user) {
      const token = await userCredential.user.getIdToken();
      const user = await getMeDelayed(token);

      return { user, token };
    }

    throw new Error('Sign up failed');
  };

  const signUpForTrial = async ({
    email,
    password,
    firstName,
    lastName,
    phone,
    country,
    address,
    farmRole,
    tz,
    trialKey,
  }: SignUpValues): Promise<User> => {
    try {
      const token = await fetch({
        uri: '/auth/sign-up',
        method: 'POST',
        variables: {
          email: email.trim(),
          password,
          firstName: firstName.trim(),
          lastName: lastName.trim(),
          phone: phone.trim(),
          country: country.trim(),
          address: address.trim(),
          farmRole: farmRole.trim(),
          tz: tz.trim(),
        },
        token: false,
      });

      const userCredential = await auth.signInWithCustomToken(token);
      if (userCredential.user) {
        const service = await MobbleService.getInstance();
        await service.api.organisation.startTrial(trialKey, country);
        const user = await getUser();
        return user;
      }
    } catch (error) {
      throw toError(error);
    }
  };

  return {
    getToken,
    getUserId,
    signIn,
    signUpForTrial,
    signOut,
    forgotPassword,
    onAuthStateChanged,
    createUserWithEmailAndPassword,
  };
};
