export const sleep = (ms: number): Promise<void> =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const promiseSequentialWithSleep = async (
  tasks: (() => Promise<any>)[],
  sleepMs = 500
): Promise<any[]> => {
  const results: any[] = [];

  for (const task of tasks) {
    const result = await task();
    results.push(result);
    await sleep(sleepMs);
  }

  return results;
};
