import React from 'react';

import { useI18n } from '@mobble/i18n';
import {
  Paddock,
  sortOptionsNameDistanceFromPaddock,
} from '@mobble/models/src/model/Paddock';
import { base64decode } from '@mobble/shared/src/core/String';
import { useEntitiesRefresher } from '@mobble/shared/src/hooks/useEntitiesRefresher';
import { useMoveMobs } from '@mobble/shared/src/hooks/useMoveMobs';
import {
  useMobs,
  usePaddock,
  usePaddockGroups,
  usePaddocks,
  useProperties,
} from '@mobble/store/src/hooks';

import { useDialog } from '@src/hooks/useDialog';
import { useLinking } from '@src/hooks/useLinking';
import { useNavigateBack } from '@src/hooks/useNavigateBack';
import { usePaddockCardFactory } from '@src/hooks/usePaddockCardFactory';
import { EntitySliceFactoryPrelude } from '@src/stories/Views/Misc/EntitySliceFactoryPrelude';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import { PaddockMoveMobForm } from '@src/stories/Views/Paddock/PaddockMoveMobForm';

import * as ROUTE_NAME from '../config/routeNames';
import { ScreenRendererProps } from '../config/types';

export const PaddockMoveMobHeader: React.FC<ScreenRendererProps> = (props) => {
  const { paddockId = '' } = props.route.params;
  const { formatMessage } = useI18n();
  const goBack = useNavigateBack();
  const paddock = usePaddock(paddockId);

  return (
    <ScreenHeader
      title={formatMessage(
        {
          defaultMessage: 'Move mobs from {PADDOCK_NAME}',
          description: 'screen.title.paddock_move_mobs',
        },
        {
          PADDOCK_NAME: paddock?.entity?.name,
        }
      )}
      onGoBack={goBack}
    />
  );
};

export const PaddockMoveMob: React.FC<ScreenRendererProps> = (props) => {
  const { paddockId = '' } = props.route.params;
  const mobIds = props.route.params?.mobIds
    ? JSON.parse(base64decode(props.route.params.mobIds))
    : [];
  const goBack = useNavigateBack();
  const linkTo = useLinking();
  const { alert, close } = useDialog();

  const { formatMessage } = useI18n();
  const properties = useProperties();
  const propertyId = properties.selected?.id;
  const paddock = usePaddock(paddockId);

  const paddocks = usePaddocks(paddock.entity?.propertyId);
  const paddockGroups = usePaddockGroups(paddock.entity?.propertyId);
  const mobs = useMobs(paddock.entity?.propertyId);

  const handleSubmitSuccess = (
    mobsCanMergeOnTargetPaddock: boolean,
    paddockTo: Paddock
  ) => {
    if (mobsCanMergeOnTargetPaddock) {
      alert(
        strings['merge.mobs.alert.title'],
        formatMessage(
          {
            defaultMessage:
              'One or more of the mobs you have moved can be merged in "{PADDOCK}"',
            description: 'mobs_can_be_merged.alert.message',
          },
          {
            PADDOCK: paddockTo.name,
          }
        ),
        [
          {
            label: strings['merge.mobs.alert.close'],
            outline: true,
            intent: 'secondary',
            onClick: () => {
              close();
              goBack();
            },
          },
          {
            label: strings['merge.mobs.alert.confirm'],
            onClick: () => {
              close();
              linkTo(ROUTE_NAME.PADDOCK_DETAIL_MOBS, {
                paddockId: paddockTo.id,
              });
            },
          },
        ]
      );
    } else {
      goBack();
    }
  };

  const {
    strings,
    formLoading,
    formError,
    moveToPropertyId,
    moveToPropertyTypes,
    propertyOptions,
    paddockOrigin,
    paddockOptions,
    sortPaddockOptions,
    handleChangeProperty,
    handleCreateCustomField,
    handleSubmit,
  } = useMoveMobs({
    paddockId,
    onSubmitSuccess: handleSubmitSuccess,
  });

  const makePaddockCard = usePaddockCardFactory({
    propertyId: propertyId,
    location: paddockOrigin,
  });

  const paddockOptionsWithCards = paddockOptions.map((p) => ({
    ...p,
    component: makePaddockCard(p.entity),
  }));

  useEntitiesRefresher([paddocks, mobs], propertyId);

  const Prelude = EntitySliceFactoryPrelude({
    preludes: [
      properties.prelude,
      paddock.prelude,
      paddocks.prelude,
      mobs.prelude,
    ],
    required: [properties.selected],
    notFound: [paddock.entity],
  });

  if (Prelude) {
    return Prelude;
  }

  return (
    <PaddockMoveMobForm
      paddockOptions={paddockOptionsWithCards}
      paddockSortOptions={sortOptionsNameDistanceFromPaddock}
      sortPaddockOptionsFunction={sortPaddockOptions}
      propertyOptions={propertyOptions}
      propertyTypes={moveToPropertyTypes}
      error={formError}
      loading={formLoading}
      paddocks={paddocks.entities}
      mobs={mobs.entities}
      selectedMobs={mobIds}
      selectedProperty={moveToPropertyId}
      selectedToPaddockId={props.route.params?.toPaddockId}
      paddock={paddock.entity}
      paddockGroups={paddockGroups.entities}
      onCancel={goBack}
      onSubmit={handleSubmit}
      onChangeProperty={handleChangeProperty}
      onCreateCustomField={handleCreateCustomField}
    />
  );
};

export default {
  name: ROUTE_NAME.MODAL_MOB_MOVE,
  header: PaddockMoveMobHeader,
  component: PaddockMoveMob,
  modal: true,
};
