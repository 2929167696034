import React from 'react';
import { Formik, type FormikErrors, useFormikContext } from 'formik';
import * as Yup from 'yup';

import { useI18n } from '@mobble/i18n';
import { isNumber } from '@mobble/shared/src/core/Number';
import { units } from '@mobble/shared/src/core/Quantity';

import { VStack } from '../../Components/Layout/Stack';

import {
  FormBuilderField,
  type FormBuilderFieldConfig,
  type FormBuilderFieldContainerProps,
  type FormBuilderFieldProps,
  type FormBuilderFieldValue,
  isNumberField,
} from './FormBuilderField';
import { FormFooter } from './FormFooter';
export {
  type FormBuilderFieldValue,
  type FormBuilderFieldConfig,
  type FormBuilderFieldProps,
} from './FormBuilderField';
import Alert, { type AlertProps } from '@src/components/Alert';

import styles from './formBuilder.scss';

export interface FormBuilderProps<FormValues = any> {
  tagName?: 'form' | 'section';
  id?: string;
  className?: string;
  i18nRootKey: string;
  fields: FormBuilderFieldConfig<FormValues>[];
  loading?: boolean;
  error?: string;
  alert?: AlertProps;
  footer?: boolean;
  reinitialize?: boolean;
  flex?: boolean;
  disabled?: boolean;
  onChange?: (
    values: FormValues,
    errors: FormikErrors<FormValues>,
    setValues: (values: any) => void,
    fieldName: string
  ) => void;
  onSubmit?: (values: FormValues) => void;
  onCancel?: () => void;
  onTouched?: (dirty: boolean) => void;
  containerComponent?: (
    props: FormBuilderFieldContainerProps<FormValues>
  ) => JSX.Element;
}

export function FormBuilder<FormValues>({
  tagName = 'form',
  id,
  i18nRootKey,
  className,
  fields,
  error,
  loading,
  alert,
  footer = true,
  reinitialize,
  flex,
  disabled,
  onSubmit,
  onChange,
  onCancel,
  onTouched,
  containerComponent,
}: FormBuilderProps<FormValues>) {
  const { translate, formatMessage } = useI18n();

  const initialValues = fields.reduce<any>(
    (values, field) => ({
      ...values,
      [field.name]: field.initialValue ?? '',
    }),
    {}
  );

  const [formValues, setFormValues] = React.useState<FormValues>(initialValues);

  const t = (args: string, params?: Record<string, string | number>) =>
    formatMessage(
      {
        id: `${i18nRootKey}.${args}`,
        defaultMessage: [],
      },
      params
    );

  const requiredTranslated = (field: string) =>
    translate({
      key: 'generic.form.validation.required',
      params: {
        '%FIELD': t(`${field}.label`) || field,
      },
    });

  const yupShape = fields.reduce((acc, field) => {
    if (typeof field.validation === 'function') {
      return {
        ...acc,
        [field.name]: field.validation({
          t,
          requiredTranslated,
          field,
          formValues,
        }),
      };
    } else if (field.validation) {
      return {
        ...acc,
        [field.name]: field.validation,
      };
    } else if (isNumberField(field)) {
      const isQuantity = field.type.startsWith('quantity');
      const fieldLabel = t(`${field.name}.label`);
      let numberValidation = Yup.number();

      if (field.min !== undefined && isNumber(field.min)) {
        numberValidation = numberValidation.min(
          Number(field.min),
          translate({
            key: 'generic.form.validation.min',
            params: { FIELD: fieldLabel, MIN: field.min },
          })
        );
      }

      if (field.max !== undefined && isNumber(field.max)) {
        numberValidation = numberValidation.max(
          Number(field.max),
          translate({
            key: 'generic.form.validation.max',
            params: { FIELD: fieldLabel, MAX: field.max },
          })
        );
      }

      if (field.required) {
        numberValidation = numberValidation.required(
          requiredTranslated(field.name)
        );
      }

      const fieldSchema = isQuantity
        ? Yup.object().shape({
            unit: Yup.string()
              .matches(
                new RegExp(units.join('|')),
                translate({
                  key: 'generic.form.validation.quantity.unit.invalid',
                })
              )
              .when('value', {
                is: (val: number | undefined) => {
                  return val && val >= 0;
                },
                then: (schema) =>
                  schema.defined(
                    translate({
                      key: 'generic.form.validation.quantity.unit.required',
                    })
                  ),
              }),

            value: numberValidation.nullable(),
          })
        : numberValidation;

      return {
        ...acc,
        [field.name]: fieldSchema,
      };
    } else if (field.required && field.type === 'select-multiple') {
      return {
        ...acc,
        [field.name]: Yup.array()
          .min(1, requiredTranslated(field.name))
          .required(requiredTranslated(field.name)),
      };
    } else if (field.required) {
      return {
        ...acc,
        [field.name]: Yup.string()
          .transform((a) => JSON.stringify(a))
          .required(requiredTranslated(field.name)),
      };
    }
    return acc;
  }, {});

  const validationSchema = Yup.object().shape(yupShape);

  const FormTouchedObserver: React.FC = () => {
    const { dirty } = useFormikContext();
    React.useEffect(() => {
      if (onTouched && dirty) {
        onTouched(dirty);
      }
    }, [dirty]);
    return null;
  };

  const proxiedOnSubmit = (values: typeof initialValues) => {
    if (typeof onSubmit === 'function') {
      onSubmit(values);
    }
  };

  return (
    <Formik
      id={id}
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={proxiedOnSubmit}
      enableReinitialize={reinitialize}
    >
      {({
        handleBlur,
        setFieldTouched,
        handleSubmit,
        values,
        errors,
        touched,
        handleReset,
        validateForm,
        setValues,
        isSubmitting,
      }) => {
        const proxySetFieldValue = (fieldName: string, value: any) => {
          const field = fields.find((f) => f.name === fieldName);
          value = field?.transform ? field.transform(value) : value;

          const fieldOnChange = field?.onChange;
          const newValues = fieldOnChange
            ? fieldOnChange(
                {
                  ...values,
                  [fieldName]: value,
                },
                fieldName
              )
            : {
                ...values,
                [fieldName]: value,
              };

          setFormValues(newValues);

          setValues(newValues, true);

          const onChangeSetNewValues = (toggleValues: any) =>
            setValues(toggleValues);

          onChange &&
            validateForm(newValues).then((_err) => {
              onChange(newValues, _err, onChangeSetNewValues, fieldName);
            });
        };

        // Focus first error field
        React.useEffect(() => {
          const errorKeys = Object.keys(errors);
          if (isSubmitting && errorKeys.length > 0) {
            let errorElement = document.getElementById(errorKeys[0]);
            const nestedError = typeof errors[errorKeys[0]] === 'object';
            if (nestedError) {
              const nestedErrorKeys = Object.keys(errors[errorKeys[0]]);
              errorElement = document.getElementById(
                `${errorKeys[0]}.${nestedErrorKeys[0]}`
              );
            }

            errorElement?.focus();
            errorElement?.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
              inline: 'start',
            });
          }
        }, [errors, isSubmitting]);

        const TagName = tagName;

        return (
          <TagName
            id={id}
            onSubmit={handleSubmit}
            className={[styles.form, className].filter(Boolean).join(' ')}
          >
            <VStack>
              {onTouched ? <FormTouchedObserver /> : <></>}
              {fields.map((field) => {
                if (field.show && field.show(values) === false) {
                  return null;
                }

                const inputProps: FormBuilderFieldProps = {
                  field,
                  values,
                  error: (errors[field.name] as any) ?? field.error,
                  touched: Boolean(touched[field.name]),
                  makeI18n: (key, params) => t(`${field.name}.${key}`, params),
                  containerComponent,
                  onChange: (value: FormBuilderFieldValue) =>
                    proxySetFieldValue(field.name, value),
                  onSetValue: (value: FormBuilderFieldValue) =>
                    proxySetFieldValue(field.name, value),
                  onTouched: () => setFieldTouched(field.name),
                  onBlur: (
                    value: FormBuilderFieldValue,
                    ev?: React.FocusEvent
                  ) => {
                    if (ev) {
                      handleBlur(ev);
                    } else {
                      setFieldTouched(field.name);
                    }
                  },
                };

                return <FormBuilderField key={field.name} {...inputProps} />;
              })}

              {alert ? <Alert {...alert} className={styles.alert} /> : null}

              <Alert
                variant="error"
                open={!!error}
                message={error}
                className={styles.alert}
              />

              {footer && typeof onSubmit === 'function' && (
                <FormFooter
                  fullWidth={flex}
                  loading={loading}
                  submitLabel={t('submit.label')}
                  disabled={disabled}
                  cancelLabel={t('cancel.label')}
                  onCancel={
                    onCancel
                      ? () => {
                          handleReset();
                          onCancel();
                        }
                      : undefined
                  }
                />
              )}
            </VStack>
          </TagName>
        );
      }}
    </Formik>
  );
}
