import React, { ChangeEvent, useEffect, useState } from 'react';

import { useI18n } from '@mobble/i18n';
import { getAllClasses, type Mob } from '@mobble/models/src/model/Mob';

import Checkbox from '../Checkbox';
import ListSelect from '../ListSelect';

export interface BulkClassSelectProps {
  /**
   * Mobs
   */
  mobs: Mob[];

  /**
   * Available classes
   */
  classes: string[];

  /**
   * Returns updated Mobs
   */
  onChange: (mobs: Mob[]) => void;
}

/**
 * BulkClassSelect displays a list of classes for multiple mobs
 */
const BulkClassSelect: React.FC<BulkClassSelectProps> = ({
  mobs,
  classes,
  onChange,
}) => {
  const { formatMessage } = useI18n();
  const [initialMobs, setInitialMobs] = useState<Mob[]>([]);
  const [classesChanged, setClassesChanged] = useState(false);
  const mobsCount = mobs.length;

  const classListOptions = classes.map((c) => {
    const selected = mobs.every((mob) => mob.classes.includes(c));
    const indeterminate =
      !selected && mobs.some((mob) => mob.classes.includes(c));

    return {
      id: c,
      value: c,
      label: c,
      indeterminate,
      selected: selected || indeterminate || false,
    };
  });

  const headingText = formatMessage(
    {
      defaultMessage: 'Classes for {count} mobs',
      description: 'Bulk class select heading text',
    },
    {
      count: mobsCount,
    }
  );

  const placeholderText = formatMessage({
    defaultMessage: 'No classes selected',
    description: 'Bulk class select placeholder text',
  });

  const selectionLabel = formatMessage({
    defaultMessage: 'No class changes',
    description: 'Bulk class select no changes selection label',
  });

  useEffect(() => {
    setInitialMobs(mobs);
  }, []);

  useEffect(() => {
    const hasChanged =
      getAllClasses(initialMobs).length !== getAllClasses(mobs).length;
    setClassesChanged(hasChanged);
  }, [JSON.stringify(initialMobs), JSON.stringify(mobs)]);

  const handleClassesChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = event.target;

    // update mobs classes
    const updatedMobs = mobs.map((mob) => {
      let newClasses = [...mob.classes];

      if (checked) {
        newClasses.push(id);
      } else {
        newClasses = mob.classes.filter((c) => c !== id);
      }
      newClasses.sort();

      return {
        ...mob,
        classes: newClasses,
      };
    });

    onChange(updatedMobs);
  };

  const handleClassOptionClick = (event) => {
    // trigger checkbox click if user clicks on the row
    if (event.target.tagName !== 'INPUT' && event.target.tagName !== 'LABEL') {
      const checkbox = event.target.querySelector('input[type="checkbox"]');
      checkbox?.click();
    }
  };

  return (
    <ListSelect
      id="bulk-class-select"
      heading={headingText}
      placeholder={placeholderText}
      selectionLabel={!classesChanged ? selectionLabel : ''}
      options={classListOptions.map((option) => ({
        ...option,
        type: 'none',
        component: (
          <Checkbox
            {...option}
            checked={option.selected}
            onChange={handleClassesChange}
          />
        ),
        onClick: handleClassOptionClick,
      }))}
      multiple
      fixedMenu
      onChange={() => {}}
    />
  );
};

export default BulkClassSelect;
