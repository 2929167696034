import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import localeData from 'dayjs/plugin/localeData';
import { CountryCodes } from '@mobble/models/src/model/Settings';

dayjs.extend(utc);
dayjs.extend(quarterOfYear);
dayjs.extend(localeData);

export type DateObject = Dayjs;

export type RawDate = string | number | DateObject | Date;

export type DateRange = {
  start: Date;
  end: Date;
};

export const dateRangeOptions = [
  'last-7-days',
  'last-4-weeks',
  'last-3-months',
  'last-12-months',
  'month-to-date',
  'quarter-to-date',
  'year-to-date',
  'last-financial-year',
  'current-financial-year',
] as const;

export type DateRangeOption = (typeof dateRangeOptions)[number];

export const fromRawDate = (rawDate: RawDate): DateObject => dayjs(rawDate);

export const fromRawDateWithTime = (rawDate: RawDate): DateObject =>
  dayjs(rawDate)
    .set('hour', dayjs().hour())
    .set('minute', dayjs().minute())
    .set('second', dayjs().second())
    .set('millisecond', dayjs().millisecond());

export const dateObjectFromString = (dateString: string): DateObject =>
  dayjs(dateString);

export const dateFromString = (a: string): Date => {
  return dayjs(a).toDate();
};

export const daysAgo = (rawDate: RawDate): number => {
  return dayjs().diff(fromRawDate(rawDate).format('YYYY-MM-DD'), 'day');
};

export const formatDate = (rawDate: RawDate, format = 'DD/MM/YYYY'): string => {
  const date = fromRawDate(rawDate);
  if (!date.isValid()) {
    return '';
  }
  return date.format(format);
};

export const formatTime = (rawDate: RawDate, format = 'HH:mm'): string => {
  const date = fromRawDate(rawDate);
  if (!date.isValid()) {
    return '';
  }
  return date.format(format);
};

export const formatDateAndTime = (
  rawDate: RawDate,
  format = 'DD/MM/YYYY HH:mm'
): string => {
  const date = fromRawDate(rawDate);
  if (!date.isValid()) {
    return '';
  }
  return date.format(format);
};

export const toISO8601 = (rawDate: RawDate = new Date()): string => {
  return dayjs(rawDate).toISOString();
};

export const daysBetween =
  (dayStart: RawDate) =>
  (dayEnd: RawDate): number => {
    return fromRawDate(dayEnd).diff(fromRawDate(dayStart), 'day');
  };

export const dateIsInThePast = (rawDate: RawDate): boolean => {
  return fromRawDate(rawDate).isBefore(dayjs());
};

export const sortByDate = (a: RawDate, b: RawDate): number => {
  return fromRawDate(b).diff(fromRawDate(a));
};

export const compareDates = (a?: RawDate, b?: RawDate): -1 | 0 | 1 => {
  if (!a && !b) {
    return 0;
  }
  if (!a) {
    return -1;
  }
  if (!b) {
    return 1;
  }
  return fromRawDate(a).diff(fromRawDate(b)) > 0 ? 1 : -1;
};

export const dateIsBetween = (
  value: RawDate,
  min?: null | RawDate,
  max?: null | RawDate
): boolean => {
  if (min && !max) {
    return fromRawDate(value).isAfter(fromRawDate(min));
  } else if (!min && max) {
    return fromRawDate(value).isBefore(fromRawDate(max));
  } else if (min && max) {
    return (
      fromRawDate(value).isAfter(fromRawDate(min)) &&
      fromRawDate(value).isBefore(fromRawDate(max))
    );
  }
  return true;
};

export const startOfYear = (year: number) => {
  return dayjs().utc().year(year).startOf('year').toISOString();
};

export const endOfYear = (year: number) => {
  return dayjs().utc().year(year).endOf('year').toISOString();
};

export const now = () => {
  return dayjs().toISOString();
};

export const yesterday = () => {
  return dayjs().subtract(1, 'day').toISOString();
};

export const subtractDays = (rawDate: RawDate, days: number): DateObject => {
  return fromRawDate(rawDate).subtract(days, 'day');
};

export const getFinancialYearStartMonth = (
  countryCode: CountryCodes
): number => {
  const financialYearStartMonths = {
    [CountryCodes.AU]: 6, // July (Jul 1 - Jun 30)
    [CountryCodes.CA]: 0, // January (Jan 1 - Dec 31)
    [CountryCodes.NZ]: 3, // April (Apr 1 - Mar 31)
    [CountryCodes.US]: 0, // January (Jan 1 - Dec 31)
  };
  return (
    financialYearStartMonths[countryCode] ??
    financialYearStartMonths[CountryCodes.AU]
  );
};

export const getDateRange = (
  type: DateRangeOption,
  countryCode: CountryCodes = CountryCodes.AU
): DateRange => {
  const fyStartMonth = getFinancialYearStartMonth(countryCode);
  const now = new Date();
  const currentMonth = now.getMonth();

  switch (type) {
    case 'last-7-days':
      return {
        start: dayjs().startOf('day').subtract(7, 'days').toDate(),
        end: dayjs().toDate(),
      };
    case 'last-4-weeks':
      return {
        start: dayjs().startOf('day').subtract(4, 'weeks').toDate(),
        end: dayjs().toDate(),
      };
    case 'last-3-months':
      return {
        start: dayjs().startOf('day').subtract(3, 'months').toDate(),
        end: dayjs().toDate(),
      };
    case 'last-12-months':
      return {
        start: dayjs().startOf('day').subtract(12, 'months').toDate(),
        end: dayjs().toDate(),
      };
    case 'month-to-date':
      return {
        start: dayjs().startOf('day').startOf('month').toDate(),
        end: dayjs().toDate(),
      };
    case 'quarter-to-date':
      return {
        start: dayjs().startOf('quarter').toDate(),
        end: dayjs().toDate(),
      };
    case 'year-to-date': {
      const start = dayjs().startOf('year');
      let end = dayjs();

      if (end.isBefore(start)) {
        end = start;
      }

      return {
        start: start.toDate(),
        end: end.toDate(),
      };
    }

    case 'last-financial-year': {
      let start = dayjs()
        .startOf('day')
        .startOf('month')
        .month(fyStartMonth)
        .subtract(1, 'year');
      let end = dayjs()
        .startOf('day')
        .startOf('month')
        .month(fyStartMonth)
        .subtract(1, 'day');

      if (currentMonth < fyStartMonth) {
        start = start.subtract(1, 'year');
        end = end.subtract(1, 'year');
      }

      return {
        start: start.toDate(),
        end: end.toDate(),
      };
    }
    case 'current-financial-year': {
      let start = dayjs().startOf('day').startOf('month').month(fyStartMonth);

      if (currentMonth < fyStartMonth) {
        start = start.subtract(1, 'year');
      }

      return {
        start: start.toDate(),
        end: dayjs().toDate(),
      };
    }
  }
};

export const getMonths = ({ short }: { short?: boolean } = {}): string[] => {
  if (short) {
    return dayjs.monthsShort();
  }
  return dayjs.months();
};

export const loadDateLocale = async (locale: string): Promise<string> => {
  switch (locale) {
    case 'es-ar':
    case 'es-cl':
    case 'es-uy':
    case 'es':
      await import('dayjs/locale/es');
      dayjs.locale('es');
      break;
    case 'ja':
      await import('dayjs/locale/ja');
      dayjs.locale('ja');
      break;
    default:
      await import('dayjs/locale/en');
      dayjs.locale('en');
      break;
  }
  return dayjs.locale();
};

/**
 * Groups an array of items by a date field using a formatting function
 * @param items Array of items to group
 * @param getDate Function to get the date from an item
 * @param formatDate Function to format the date into a string
 * @returns Array of SectionedItems grouped by formatted date
 */
export function groupItemsByDate<T>(
  items: T[],
  getDate: (item: T) => Date | RawDate,
  formatDate: (date: Date | RawDate) => string
): { formattedDate: string; data: T[] }[] {
  const groupedItems = items.reduce((acc, item) => {
    const date = formatDate(getDate(item));
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(item);
    return acc;
  }, {} as Record<string, T[]>);

  return Object.entries(groupedItems).map(([date, data]) => ({
    formattedDate: date,
    data,
  }));
}
