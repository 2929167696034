import React from 'react';
import classNames from 'classnames/bind';

import { IconName } from '@mobble/icon';
import { Color } from '@mobble/theme';

import { ColorSwatch, Icon, Text } from '@src/components';
import { RootStackParamList } from '@src/screens/config/types';

import { Box } from '../Layout/Box';
import { Spacer } from '../Layout/Spacer';
import { HStack, VStack } from '../Layout/Stack';

import { Clickable } from './Clickable';

import styles from './NavigationList.scss';
const cx = classNames.bind(styles);

export interface NavigationItemProps {
  title: string;
  subtitle?: string;
  description?: string;
  href?: keyof RootStackParamList | (() => void);
  icon?: IconName;
  disabled?: boolean;
  color?: string;
}

export interface NavigationGroupProps {
  title?: string;
  items: NavigationItemProps[];
}

export interface NavigationListProps {
  items: NavigationGroupProps[] | NavigationItemProps[];
  variant?: 'list' | 'card';
}

const NavigationItem: React.FC<
  NavigationItemProps & { variant: 'list' | 'card' }
> = ({
  title,
  subtitle,
  description,
  href,
  icon,
  disabled,
  color,
  variant,
}) => {
  if (variant === 'card') {
    return (
      <Clickable href={href} className={styles.cardNavigationListGridItem}>
        <Box className={[disabled ? styles.cardDisabled : null]}>
          <Box className={styles.cardTitle}>
            {icon && (
              <Icon
                name={icon}
                color={
                  disabled ? Color.NeutralsCoreDisabled : Color.PrimaryDefault
                }
              />
            )}
            <Text tagName="h2" bold>
              {title}
            </Text>
          </Box>

          {subtitle && (
            <Text tagName="p" bold className={styles.cardSubtitle}>
              {subtitle}
            </Text>
          )}
        </Box>
      </Clickable>
    );
  }

  return (
    <Clickable fullWidth href={href}>
      <Box
        flex
        background={disabled ? Color.NeutralsBgDisabled : Color.BodyBackground}
      >
        <Box spacing={2} flex>
          <HStack>
            {color && (
              <>
                <ColorSwatch color={color} />
                <Spacer x={2} />
              </>
            )}
            {icon && (
              <>
                <Icon
                  name={icon}
                  color={
                    disabled ? Color.NeutralsCoreDisabled : Color.PrimaryDefault
                  }
                />
                <Spacer x={2} />
              </>
            )}
            <VStack>
              <HStack alignment="center">
                <Text>{title}</Text>
              </HStack>
              {(description || subtitle) && (
                <Text variant="body" color={Color.NeutralsCoreDefault}>
                  {description || subtitle}
                </Text>
              )}
            </VStack>
          </HStack>
        </Box>
      </Box>
    </Clickable>
  );
};

export const NavigationList: React.FC<NavigationListProps> = ({
  items,
  variant = 'list',
}) => {
  const rootClasses = cx({
    NavigationList: variant !== 'card',
    CardedNavigationList: variant === 'card',
  });

  const isGrouped = items.length > 0 && 'items' in items[0];
  const groupedItems = isGrouped
    ? (items as NavigationGroupProps[])
    : [{ items: items as NavigationItemProps[] }];

  return (
    <nav className={rootClasses}>
      {groupedItems.map((group, groupIndex) => (
        <section key={group.title || groupIndex}>
          <header>
            <h2>{group.title}</h2>
          </header>

          <ul>
            {group.items.map((item, itemIndex) => (
              <li key={itemIndex}>
                <NavigationItem {...item} variant={variant} />
              </li>
            ))}
          </ul>
        </section>
      ))}
    </nav>
  );
};
