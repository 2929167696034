import { useState } from 'react';
import { defineMessages } from 'react-intl';

import { useI18n, useMessages } from '@mobble/i18n';
import {
  findMobs,
  getMobDisplayName,
  someMobsCanMerge,
} from '@mobble/models/src/model/Mob';
import {
  Paddock,
  findPaddock,
  sortAnyContainingPaddocks,
} from '@mobble/models/src/model/Paddock';
import { findPaddockCenterPoint } from '@mobble/models/src/model/PaddockGeometry';
import { ConfiguredPropertyTypeGroupCustom } from '@mobble/models/src/model/Property';
import { FetcherError } from '@mobble/service/src/lib/Fetcher';
import { thunkGetAll as thunkGetAllMobs } from '@mobble/store/src/reducers/mobs';
import {
  thunkGetAll as thunkGetAllPaddocks,
  thunkMergeMobs,
  thunkMoveMobs,
  type MergeMobsInput,
  type MoveMobsInput,
} from '@mobble/store/src/reducers/paddocks';
import {
  useMobs,
  usePaddock,
  usePaddockGeometries,
  usePaddocks,
  useProperties,
  useRootDispatch,
} from '@mobble/store/src/hooks';
import { arrayUnique } from '../core/Array';
import { RequestErrors } from '../core/Error';

export const BACKDATING_DAYS_LIMIT = 14;

export interface MoveMobsArgs extends Omit<MoveMobsInput, 'toPaddockId'> {
  paddockFrom: Paddock;
  paddockTo: Paddock;
  meta?: {
    mobMoveNames: string[];
    mobSplitNames: string[];
  };
}

export interface MergeMobsArgs extends MergeMobsInput {
  meta?: {
    paddockNames: string;
    mobsNames: string[];
  };
}

export interface PaddockMoveMobFormValues {
  mobs: string[];
  paddockNameFrom: string;
  paddock: string;
  mobsSplit: string;
  property: string;
  date: string;
}

export interface PaddockMoveMobFormQuickValues
  extends Omit<PaddockMoveMobFormValues, 'mobsSplit' | 'property' | 'date'> {}

const messages = defineMessages({
  'merge.mobs.alert.title': {
    defaultMessage: 'Mobs can be merged',
    description: 'mobs_can_be_merged.alert.title',
  },
  'merge.mobs.alert.close': {
    defaultMessage: 'Close',
    description: 'mobs_can_be_merged.alert.close',
  },
  'merge.mobs.alert.confirm': {
    defaultMessage: 'View mobs',
    description: 'mobs_can_be_merged.alert.confirm',
  },
});

export const useMoveMobs = ({
  paddockId,
  onSubmitSuccess,
}: {
  paddockId: string;
  onSubmitSuccess: (
    mobsCanMergeOnTargetPaddock: boolean,
    toPaddock: Paddock
  ) => void;
}) => {
  const dispatch = useRootDispatch();
  const { formatMessage } = useI18n();
  const properties = useProperties();
  const propertyId = properties.selected?.id;
  const paddock = usePaddock(paddockId);
  const paddocks = usePaddocks(paddock.entity?.propertyId);
  const paddockGeometries = usePaddockGeometries(paddock.entity?.propertyId);
  const mobs = useMobs(paddock.entity?.propertyId);
  const strings = useMessages(messages);

  const [formLoading, setFormLoading] = useState(false);
  const [formError, setFormError] = useState('');

  const [moveToPropertyId, setMoveToPropertyId] = useState<string>(
    propertyId ?? ''
  );
  const currentOrganisationId = properties.selected?.organisationId;
  const moveToPropertyTypes =
    properties.entities.find((a) => a.id === moveToPropertyId)?.types ??
    properties.selected?.types ??
    [];

  const propertyOptions = properties.entities
    .filter((p) => p.organisationId === currentOrganisationId)
    .map((property) => ({
      label: property.name,
      value: property.id,
    }));

  const paddockOptions = paddocks.allEntitiesAvailable
    .filter((p) => p.propertyId === moveToPropertyId)
    .map((p) => ({
      label: p.name,
      value: p.id,
      entity: p,
    }));

  const paddockOrigin = findPaddockCenterPoint(paddockGeometries.entities)(
    paddockId
  );

  const paddockSortMeta = {
    origin: paddockOrigin,
    paddockGeometries: paddockGeometries.allEntitiesAvailable,
    mobs: mobs.allEntitiesAvailable,
  };

  const sortPaddockOptions = sortAnyContainingPaddocks((o) => o.entity)(
    paddockSortMeta
  );

  const getAllPaddocksAndMobsForProperties = (propertyIds: string[]) => {
    return Promise.all(
      propertyIds.reduce<Promise<any>[]>((acc, parentId) => {
        return [
          ...acc,
          dispatch(thunkGetAllPaddocks({ parentId })),
          dispatch(thunkGetAllMobs({ parentId })),
        ];
      }, [])
    );
  };

  const moveMobs = async (
    input: MoveMobsArgs
  ): Promise<{
    mobsCanMergeOnTargetPaddock: boolean;
    toPaddockId: string;
  }> => {
    try {
      await dispatch(
        thunkMoveMobs({
          propertyId: input.paddockTo.propertyId,
          toPaddockId: input.paddockTo.id,
          mobIds: input.mobIds,
          date: input.date,
          mobsSplit: input.mobsSplit,

          // required offline actions
          paddockFrom: input.paddockFrom,
          paddockTo: input.paddockTo,
          meta: input.meta,
        })
      ).unwrap();

      const propertyIds = arrayUnique([
        input.paddockFrom.propertyId,
        input.paddockTo.propertyId,
      ]);
      const [allPaddocksResult, allMobsResult] =
        await getAllPaddocksAndMobsForProperties(propertyIds);
      const allPaddocks = allPaddocksResult.payload.entities;
      const allMobs = allMobsResult.payload.entities;
      const paddockToObj = findPaddock(allPaddocks)(input.paddockTo.id);
      const paddockMobs = paddockToObj
        ? findMobs(allMobs)(paddockToObj.mobs)
        : [];
      const mobsCanMergeOnTargetPaddock = someMobsCanMerge(paddockMobs);

      return {
        mobsCanMergeOnTargetPaddock,
        toPaddockId: input.paddockTo.id,
      };
    } catch (error) {
      if (error.message === RequestErrors.NetworkError) {
        return {
          mobsCanMergeOnTargetPaddock: false,
          toPaddockId: input.paddockTo.id,
        };
      }
      return Promise.reject(error);
    }
  };

  const mergeMobs = async (input: MergeMobsArgs) => {
    try {
      const res = await dispatch(thunkMergeMobs(input)).unwrap();
      const propertyIds = [input.propertyId];
      await getAllPaddocksAndMobsForProperties(propertyIds);

      return Promise.resolve(res);
    } catch (error) {
      // Handle when offline
      if (error.message === RequestErrors.NetworkError) {
        Promise.resolve(input);
      }
      return Promise.reject(error);
    }
  };

  const handleChangeProperty = (changedPropertyId: string) => {
    dispatch(thunkGetAllPaddocks({ parentId: changedPropertyId }));
    setMoveToPropertyId(changedPropertyId);
  };

  const handleCreateCustomField = (
    group: ConfiguredPropertyTypeGroupCustom,
    parentId: string,
    label: string
  ) => {
    if (!properties.selected) {
      return Promise.reject();
    }
    return properties.addPropertyType({
      group,
      parentId,
      label,
    });
  };

  const handleSubmit = (
    formValues: PaddockMoveMobFormValues | PaddockMoveMobFormQuickValues
  ) => {
    if (
      formLoading ||
      paddocks.entities.length === 0 ||
      !paddock.entity ||
      !mobs
    ) {
      return;
    }

    const paddockTo = findPaddock(paddocks.allEntitiesAvailable)(
      formValues.paddock
    );
    if (!paddockTo) {
      return;
    }

    setFormError('');
    setFormLoading(true);

    const mobsSplit =
      'mobsSplit' in formValues ? JSON.parse(formValues.mobsSplit) : {};

    // offline actions
    const actionMeta = {
      mobMoveNames: formValues.mobs.map(getMobDisplayName(mobs.entities)),
      mobSplitNames: mobsSplit
        ? Object.keys(mobsSplit).map((mobId) =>
            getMobDisplayName(mobs.entities)(mobId)
          )
        : [],
    };

    moveMobs({
      propertyId: paddockTo.propertyId,
      paddockFrom: paddock.entity,
      paddockTo: paddockTo,
      mobIds: formValues.mobs,
      date: 'date' in formValues ? formValues.date : undefined,
      mobsSplit,
      meta: actionMeta,
    })
      .then(({ mobsCanMergeOnTargetPaddock }) => {
        // TODO: replace with Toast/Alert
        console.log(`Mob successfully moved`);
        setFormLoading(false);
        onSubmitSuccess(mobsCanMergeOnTargetPaddock, paddockTo);
      })
      .catch((err: FetcherError) => {
        setFormLoading(false);

        switch (err.internalCode) {
          case 7000:
            setFormError(
              formatMessage(
                {
                  defaultMessage:
                    'Backdating is limited to {BACKDATING_DAYS_LIMIT} days. Please select a more recent date.',
                  description: 'Mob move error: Backdating exceeds limit.',
                },
                {
                  BACKDATING_DAYS_LIMIT: BACKDATING_DAYS_LIMIT,
                }
              )
            );
            break;
          case 7103:
          case 7210:
          case 7202:
          case 7203:
          case 7204:
            setFormError(
              formatMessage(
                {
                  defaultMessage:
                    'Cannot backdate this movement as selected date is earlier than the last resting or grazing date in <b>{PADDOCK_NAME}</b>.',
                  description:
                    'Mob move error: Backdating violates resting/grazing history.',
                },
                {
                  PADDOCK_NAME: err.details[0]?.paddockName,
                }
              )
            );
            break;
          case 7100:
          case 7101:
          case 7200:
            setFormError(
              formatMessage({
                defaultMessage:
                  'Backdating mob move failed for an unknown reason, please contact support at <b>hello@mobble.io.</b>',
                description: 'Mob move error: Backdating unknown reason.',
              })
            );
            break;
          default:
            setFormError(err.message);
        }
      });
  };

  return {
    strings,
    formLoading,
    formError,
    moveToPropertyId,
    moveToPropertyTypes,
    propertyOptions,
    paddockOptions,
    paddockOrigin,
    sortPaddockOptions,
    mergeMobs,
    moveMobs,
    handleChangeProperty,
    handleCreateCustomField,
    handleSubmit,
  };
};
